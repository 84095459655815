import React from "react";
import { object, bool } from "prop-types";
import { Link, navigate } from "gatsby";
import { motion } from "framer-motion";
import { isMobileOnly, isMobile } from "react-device-detect";
import BusinessTypesImages from "../../components/ImageQuerys/BusinessTypesImages";
import Heading from "../Base/HeadingBuilder";
import rightArrow from "../../images/svg/right-arrow.svg";
import { colors } from "../../style-vars";
import useStartMotion from "../../hooks/use-start-motion";

const variants = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const BusinessTypes = ({ sectionData, animations }) => {
  const { title, cards } = sectionData;
  const { ref, controls } = useStartMotion();

  return (
    <motion.section
      className="flex flex-col mx-4 lg:mx-14 max-w-1440 mt-20 lg:mt-36"
      ref={ref}
      animate={controls}
      variants={variants}
      transition={{ duration: 0.8 }}
      initial={isMobileOnly || !animations ? "visible" : "hidden"}
    >
      {title && (
        <Heading
          level={2}
          injectionType={2}
          className="lg:text-center lg:px-24 xl:px-72 2xl:px-80 lg:mb-20"
          withBlueSymbol
        >
          {title}
        </Heading>
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 xl:gap-x-6 gap-y-10">
        <Link
          to={cards[0].linkTo}
          className="b-types-card relative overflow-hidden rounded-lg"
        >
          <BusinessTypesImages className="rounded-lg" imageName={cards[0].bg} />
          <div className="absolute left-4 md:left-8 bottom-8 max-w-80-percent md:max-w-60-percent z-1">
            <div className="text-24 lg:text-32 text-white mb-2">
              {cards[0].cardTitle}
            </div>
            <div className="text-24 lg:text-32 font-bold text-white mb-8 leading-tight">
              {cards[0].cardContent}
            </div>
            <img className="" src={rightArrow} alt="right arrow" />
          </div>
          <div className="b-types__card--hover-circle" />
        </Link>

        <Link
          to={cards[1].linkTo}
          className="b-types-card relative overflow-hidden rounded-lg"
        >
          <BusinessTypesImages className="rounded-lg" imageName={cards[1].bg} />
          <div className="absolute left-4 md:left-8 bottom-8 max-w-80-percent md:max-w-60-percent z-1">
            <div className="text-24 lg:text-32 text-white mb-2">
              {cards[1].cardTitle}
            </div>
            <div className="text-24 lg:text-32 font-bold text-white mb-8 leading-tight">
              {cards[1].cardContent}
            </div>
            <img
              className="b-types__card--arrow"
              src={rightArrow}
              alt="right arrow"
            />
          </div>
          <div className="b-types__card--hover-circle" />
        </Link>

        <Link
          to={cards[2].linkTo}
          className="b-types-card relative overflow-hidden rounded-lg"
        >
          <BusinessTypesImages className="rounded-lg" imageName={cards[2].bg} />
          <div className="absolute left-4 md:left-8 bottom-8 max-w-80-percent md:max-w-60-percent z-1">
            <div className="text-24 lg:text-32 text-white mb-2">
              {cards[2].cardTitle}
            </div>
            <div className="text-24 lg:text-32 font-bold text-white mb-8 leading-tight">
              {cards[2].cardContent}
            </div>
            <img
              className="b-types__card--arrow"
              src={rightArrow}
              alt="right arrow"
            />
          </div>
          <div className="b-types__card--hover-circle" />
        </Link>
      </div>

      <style jsx global>{`
        .b-types-card .gatsby-image-wrapper {
          padding-bottom: 1px !important;
        }

        .b-types__card--hover-circle {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 436px;
          height: 436px;
          border-radius: 50%;
          background: ${colors.primary};
          z-index: 0;
          transition: 0.5s ease-out;
          transform: translate(-100%, 100%);
          //opacity: 0;
        }

        .b-types-card:hover .b-types__card--hover-circle {
          transition: 0.5s ease-out;
          transform: translate(-8%, 20%);
        }
      `}</style>
    </motion.section>
  );
};

BusinessTypes.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sectionData: object.isRequired,
  animations: bool,
};

BusinessTypes.defaultProps = {
  animations: true,
};

export default BusinessTypes;
